import { addItemCommand } from './commands/addItemCommand'
import { applyCouponCommand } from './commands/applyCouponCommand'
import { loadCartCommand } from './commands/loadCartCommand'
import { loadTotalQtyCommand } from './commands/loadTotalQtyCommand'
import { removeCouponCommand } from './commands/removeCouponCommand'
import { removeItemCommand } from './commands/removeItemCommand'
import { updateItemQtyCommand } from './commands/updateItemQtyCommand'
import type { Cart, CartItemInterface, Product, ProductInterface } from '@vue-storefront/magento-types'
import type { UseCartErrors, UseCartInterface } from './useCart'
import { cartCookieName } from '~/enums/cookieNameEnum'
import useGTMComposable from '~/composables/useGTM'
import { handleCartError } from '~/composables/useCart/utils/handleCartError'

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<
  CART extends Cart,
  CART_ITEM extends CartItemInterface,
  PRODUCT extends ProductInterface,
>(): UseCartInterface<CART, CART_ITEM, PRODUCT> {
  const { trackCartChange } = useGTMComposable()
  const {
    public: { cartCookieTTL },
  } = useRuntimeConfig()
  const loading = ref<boolean>(false)
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  })
  const cartStore = useCartStore()
  const cart = computed(() => cartStore.cart as CART)

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    console.debug('useCart.setCart', newCart)

    const filteredCart = newCart ? { ...newCart, items: newCart.items?.filter((item) => item !== null) } : null

    cartStore.setCart(
      filteredCart ?? {
        id: '',
        is_virtual: false,
        total_quantity: 0,
        shipping_addresses: [],
      },
    )

    updateCookieQuantity()
  }

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean =>
    !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid)

  const load = async ({ realCart, completeCart } = { realCart: false, completeCart: false }): Promise<void> => {
    console.debug('useCart.load')
    try {
      loading.value = true
      const loadedCart = await loadCartCommand.execute({
        realCart,
        completeCart,
      })
      cartStore.setCart(loadedCart)
      error.value.load = null
      updateCookieQuantity()
    } catch (err) {
      error.value.load = err
      handleCartError('useCart/load', err, false)
    } finally {
      loading.value = false
    }
  }

  const loadTotalQty = async (): Promise<void> => {
    console.debug('useCart.loadTotalQty')

    try {
      loading.value = true

      // Try to read cart quantity from vsf-cart cookie: "quantity<number>|cartId<string>"
      const cartCookie = useCookie(cartCookieName)
      const totalQuantity = cartCookie.value?.indexOf('|')
        ? parseInt(cartCookie.value?.split('|').shift())
        : await loadTotalQtyCommand.execute()

      cartStore.$patch((state) => {
        if (state.cart === null) setCart(null)
        state.cart.total_quantity = totalQuantity
      })
      updateCookieQuantity()
    } catch (err) {
      error.value.loadTotalQty = err
    } finally {
      loading.value = false
    }
  }

  const addItem = async ({
    product,
    quantity,
    productConfiguration,
    productCustomOptionsConfiguration,
  }): Promise<void> => {
    console.debug('useCart.addItem', { product, quantity })
    const cartCookie = useCookie(cartCookieName)
    const variant = product.variants?.find((variant) =>
      variant?.attributes?.every((attr) => Object.values(productConfiguration).includes(attr?.uid)),
    )?.product?.sku

    try {
      loading.value = true

      if (!cartCookie.value) {
        await load({ realCart: true })
      }

      await addItemCommand.execute({
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        productCustomOptionsConfiguration,
      })

      error.value.addItem = null
      updateCookieQuantity()
      trackCartChange('addToCart', {
        product,
        sku: product.sku,
        variant,
        quantity,
      })
    } catch (err) {
      error.value.addItem = err
      handleCartError('useCart/addItem', err, true)
    } finally {
      loading.value = false
      // Handled by Exponea until we implement that solution here.
      // sendNotification({
      //   id: Symbol('product_added'),
      //   message: t('Added to your cart!'),
      //   type: 'success',
      //   icon: 'check',
      //   persist: false,
      // })
    }
  }

  const removeItem = async ({ product }) => {
    console.debug('useCart.removeItem', { product })

    try {
      loading.value = true
      await removeItemCommand.execute({
        product,
      })

      error.value.removeItem = null
      updateCookieQuantity()
      trackCartChange('removeFromCart', {
        product: {
          ...product.product,
          pid: product.product.id,
          variant_id: product.configured_variant?.id,
        },
        sku: product.product.sku,
        variant: product.configured_variant?.sku,
        quantity: product.quantity,
      })
    } catch (err) {
      error.value.removeItem = err
      handleCartError('useCart/removeItem', err, false)
    } finally {
      loading.value = false
    }
  }

  const updateItemQty = async ({ product, quantity }): Promise<void> => {
    console.debug('useCart.updateItemQty', {
      product,
      quantity,
    })

    if (quantity && quantity > 0) {
      try {
        loading.value = true
        await updateItemQtyCommand.execute({
          product,
          quantity,
        })

        error.value.updateItemQty = null
        updateCookieQuantity()

        if (product.quantity < quantity) {
          trackCartChange('addToCart', {
            product: {
              ...product.product,
              pid: product.product.id,
              variant_id: product.configured_variant?.id,
            },
            sku: product.product.sku,
            variant: product.configured_variant?.sku,
            quantity: product.quantity,
          })
        } else {
          trackCartChange('removeFromCart', {
            product: {
              ...product.product,
              pid: product.product.id,
              variant_id: product.configured_variant?.id,
            },
            sku: product.product.sku,
            variant: product.configured_variant?.sku,
            quantity: product.quantity,
          })
        }
      } catch (err) {
        error.value.updateItemQty = err
        handleCartError('useCart/updateItemQty', err, true)
      } finally {
        loading.value = false
      }
    }
  }

  const handleCoupon = async (couponCode = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      couponCode,
    }

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(variables)
      : await removeCouponCommand.execute(variables)

    if (errors) {
      throw errors
    }

    if (updatedCart) {
      cartStore.setCart(updatedCart)
    }
  }

  const applyCoupon = async ({ couponCode }): Promise<void> => {
    console.debug('useCart.applyCoupon')

    try {
      loading.value = true
      await handleCoupon(couponCode)
      error.value.applyCoupon = null
    } catch (err) {
      error.value.applyCoupon = err
      handleCartError('useCart/applyCoupon', err, true)
    } finally {
      loading.value = false
    }
  }

  const removeCoupon = async (): Promise<void> => {
    console.debug('useCart.removeCoupon')

    try {
      loading.value = true
      await handleCoupon(null)
      error.value.applyCoupon = null
    } catch (err) {
      error.value.removeCoupon = err
      handleCartError('useCart/removeCoupon', err, true)
    } finally {
      loading.value = false
    }
  }

  const canAddToCart = (product: Product, qty = 1) => {
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant?.uid
    }
    const inStock = product?.stock_status ?? ''
    const stockLeft = product?.only_x_left_in_stock === null ? true : qty <= product?.only_x_left_in_stock
    return inStock && stockLeft
  }

  const removeMagentoLocalStorageKeys = () => {
    const keys = ['cart', 'minicart']
    const storage = JSON.parse(localStorage['mage-cache-storage'] ?? '{}')
    keys.forEach((key) => delete storage[key])
    localStorage['mage-cache-storage'] = JSON.stringify(storage)
  }

  const updateCookieQuantity = () => {
    const cartCookie = useCookie(cartCookieName, {
      maxAge: cartCookieTTL,
      sameSite: 'lax',
    })
    const realCartId = cartCookie.value?.indexOf('|') ? cartCookie.value.split('|').pop() : cartCookie.value

    if (!realCartId) {
      cartCookie.value = undefined
      return
    }

    const cartTotalQuantity = cartStore.cart?.total_quantity ?? 0

    cartCookie.value = `${cartTotalQuantity}|${realCartId}`

    removeMagentoLocalStorageKeys()
  }

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  }
}

export default useCart
export * from './useCart'
