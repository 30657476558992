import button from './config/button'
import colors from './config/colors'
import input from './config/input'
import link from './config/link'
import drawer from './config/drawer'

export default defineAppConfig({
  ui: {
    ...colors,
    ...button,
    ...input,
    ...link,
    ...drawer,
  },
})
