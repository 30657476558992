import _debounce from '~/utils/debounce'
import type { Product } from '~/types/product'
import { Buffer } from 'buffer'

enum ListType {
  'catalog_product_view' = 'detail',
  'catalog_category_view' = 'category',
  'cms_index_index' = 'other',
  'cms_page_view' = 'other',
}

interface CartItem {
  product: Product
  sku: string
  variant: string
  quantity: number
}

export const useGTMComposable = () => {
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const gtm = useGtm()
  const route = useRoute()
  const cartStore = useCartStore()
  const customerStore = useCustomerStore()
  const pageStore = usePageStore()
  const bloomreachDiscoveryStore = useBloomreachDiscoveryStore()
  const productStore = useProductStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const mapCartItems = (items: typeof cartStore.cart.items) =>
    items
      ?.filter((item) => !!item)
      .map((item) => {
        return {
          sku: (item as any).configured_variant?.sku || item.product?.sku, // 'MJ07235-0100-S',
          parent_sku: item.product?.sku, // 'MJ07235-0100',
          name: item.product?.name, //  'Bruine rib top met col',
          product_type: (item.product as any)?.__typename?.replace('Product', '').toLowerCase() || 'simple', // 'configurable' | 'simple' | ...
          price: Math.round(((item.prices?.row_total_including_tax?.value ?? 0) / 1.21) * 100) / 100, // 21.48,
          price_incl_tax: item.prices?.row_total_including_tax?.value ?? 0, // 25.99,
          discount_amount: item.prices?.total_item_discount?.value ?? 0,
          tax_amount: Math.round(((item.prices?.row_total_including_tax?.value ?? 0) / 1.21) * 0.21 * 100) / 100,
          quantity: item.quantity,
          variant:
            (item as any).configurable_options?.map((option: { value_label: any }) => option.value_label).join(' / ') ||
            '',
          categories: item.product?.categories?.map((cat) => cat?.name) || [],
          category: item.product?.categories?.[0]?.name || '',
          all_categories:
            Object.fromEntries(
              item.product?.categories?.map((cat) => [Buffer.from(cat?.uid ?? '', 'base64').toString(), cat?.name]) ||
                [],
            ) || [],
          p_id: parseInt(Buffer.from(item.product?.uid ?? '', 'base64').toString()) || 0,
        }
      })

  const trackCustomerSession = _debounce(() => {
    const customer = {
      isLoggedIn: customerStore.isLoggedIn,
      id: undefined,
      groupId: customerStore.isLoggedIn ? '1' : undefined,
      email: customerStore.user?.email || undefined,
    }

    Object.keys(customer).forEach((key) => customer[key] === undefined && delete customer[key])

    const cartQty = cartStore.cart?.total_quantity || 0

    const session = {
      event: 'mpCustomerSession',
      customer: {
        ...customer,
      },
      cart: {
        hasItems: cartQty > 0,
        items: mapCartItems(cartStore.cart?.items),
        total: cartStore.cart?.prices?.subtotal_including_tax?.value || 0,
        itemCount: cartStore.cart?.items?.length || 0,
        cartQty,
        hasCoupons: Boolean(cartStore.cart?.applied_coupons),
      },
    }

    gtm?.push(session)
  }, 500)

  const handleCategory = async (page: any, to: any) => {
    page.pageType = 'catalog_category_view'
    page.list = 'category'

    // Wait until bloomreachDiscovery and page are loaded
    // skip if loading takes longer than 5 seconds
    await new Promise<void>((resolve) => {
      const timeout = setTimeout(resolve, 5000)
      const checkLoaded = () => {
        if (!bloomreachDiscoveryStore.loading && pageStore.loaded) {
          clearTimeout(timeout)
          resolve()
        } else {
          requestAnimationFrame(checkLoaded)
        }
      }
      checkLoaded()
    })

    const category = {
      event: 'categoryPage',
      category: {
        id: pageStore.routeData?.id,
        name: pageStore.pageData?.title,
        path: pageStore.routeData?.relative_url.replaceAll('.html', '').replaceAll('/', ' > '),
        productCount: bloomreachDiscoveryStore.searchResponse?.response?.numFound || 0,
        productsPageLoaded: bloomreachDiscoveryStore.searchResponse?.response?.docs?.length,
        page: to.query.p || 1,
      },
    }
    gtm.push(category)
  }

  const handleProduct = async (page: any, to: any) => {
    page.pageType = 'catalog_product_view'
    page.list = 'detail'

    const sku = pageStore?.routeData?.sku
    await productStore.waitForProduct(sku)
    const product = productStore.products[sku]

    if (!product) return

    const productPage = {
      event: 'productPage',
      product: {
        all_categories: product.categories?.reduce((acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {}),
        attribute_set_id: null,
        category: breadcrumbsStore.breadcrumbs
          ? Array.from(breadcrumbsStore.breadcrumbs).pop()?.text
          : product.categories?.[0],
        id: product.pid,
        image_url: product.image?.url,
        name: product.name,
        p_id: product.pid,
        parent_sku: null,
        path: pageStore.routeData?.relative_url.replaceAll('.html', '').replaceAll('/', ' > '),
        price: product.price_range?.minimum_price?.final_price?.value,
        product_type: product.__typename?.replace('Product', '').toLowerCase() || 'simple',
        sku,
        variants: product.variants?.map((variant, index) => ({
          id: variant?.product.sku,
          p_id: variant?.product.id,
          position: index,
          stock_status: variant?.product.stock_status,
          name: variant?.attributes?.[0]?.label,
        })),
        lookup: {
          [sku]: product?.pid,
          ...product?.variants?.reduce((acc, curr) => {
            acc[curr.product.sku] = curr.product.id;
            return acc;
          }, {}),
        },
      },
    }

    const productDetail = {
      event: 'productDetail',
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              id: product?.sku,
              p_id: product?.pid,
              name: product?.name,
              position: 0,
              product_type: product?.__typename?.replace('Product', '').toLowerCase() || 'simple',
            },
            ...(product?.variants
              ?.filter((variant) => !!variant)
              ?.map((variant, index) => ({
                id: variant.product?.sku,
                p_id: variant.product?.id,
                name: variant.attributes?.[0]?.label,
                position: index + 1,
                product_type: variant.product?.__typename?.replace('Product', '').toLowerCase() || 'simple',
              })) || []),
          ],
        },
      },
    }
    gtm.push(productPage)
    gtm.push(productDetail)
  }

  const trackPageEvent = async () => {
    const to = route

    // Skip GTM when within Experience Manager.
    if (route?.query?.token) {
      return
    }
    const fullPath = to.fullPath.replace('/' + locale, '')
    const pageType: string = fullPath === '/' ? 'cms_index_index' : 'cms_page_view'
    const list = ListType[pageType]

    const page = {
      ecommerce: {
        currencyCode: 'EUR',
      },
      pageType: to.meta?.type || pageType,
      pageUrl: to.fullPath,
      list,
    }

    switch (to.meta?.type) {
      case 'CATEGORY':
        handleCategory(page, to)
        break
      case 'PRODUCT':
        handleProduct(page, to)
        break
      default:
        // set tracking for regular pages
        break
    }

    gtm.push(page)
  }

  const trackCartChange = (event: string, cartItem: CartItem) => {
    const p = cartItem.product
    const p_id =
      cartItem.product.variant_id ||
      cartItem.product.variants?.find((variant) => variant.product.sku === cartItem.variant)?.product?.id
    const product = {
      name: p.name,
      id: cartItem.sku || cartItem.id,
      price: p?.price_range?.maximum_price?.final_price?.value,
      quantity: cartItem.quantity,
      parent_sku: p.sku,
      variant: cartItem.variant,
      category: p.categories?.[0]?.name,
      all_categories: p.categories?.map((category) => category.name),
      p_id,
      parent_p_id: p.pid,
    }

    // @todo: check simple products

    const payload = {
      event,
      ecommerce: {
        currencyCode: 'EUR',
      },
      cart: {},
    }

    payload.ecommerce[event === 'addToCart' ? 'add' : 'remove'] = { products: [product] }
    payload.cart[event === 'addToCart' ? 'add' : 'remove'] = { products: [product] }
    gtm.push(payload)
  }

  const init = () => {
    // Skip GTM when within Experience Manager.
    if (route?.query?.token) {
      return
    }

    trackCustomerSession()

    cartStore.$subscribe(() => {
      trackCustomerSession()
    })

    customerStore.$subscribe(() => {
      trackCustomerSession()
    })
  }

  return {
    init,
    trackCartChange,
    trackPageEvent,
  }
}

export default useGTMComposable
