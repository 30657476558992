import { cartCookieName, customerCookieName, loginRedirectCookieName } from '~/enums/cookieNameEnum'
import type { RouteLocationNormalizedGeneric } from 'vue-router'

// Deduce if a pretty URL is being used, if so make sure the renderKey stays the same so the page doesn't re-render
const generateRenderKey = (to: RouteLocationNormalizedGeneric, from: RouteLocationNormalizedGeneric) => {
  if (to.path.includes('.html') && to.path.split('/').length < 4) {
    to.meta.key = to.path.replace('.html', '')
  } else if (to.path.includes('.html')) {
    const { prettyUrls } = storeToRefs(usePrettyUrlStore())

    const getEndPart = (path: string) => {
      return path
        .substring(path.lastIndexOf('/') + 1)
        .replace('.html', '')
        .split('-')
    }

    const getMatchedUrl = (endPart: string[]) => {
      return endPart.find((url) => prettyUrls.value.find((prettyUrl) => prettyUrl.slug === url))
    }

    const getRelativeUrl = (path: string) => {
      return path.substring(0, path.lastIndexOf('/'))
    }

    const toEndPart = getEndPart(to.path)
    const toMatchedPrettyUrl = getMatchedUrl(toEndPart)
    const toRelativeUrl = getRelativeUrl(to.path)
    const fromEndPart = getEndPart(from.path)
    const fromMatchedPrettyUrl = getMatchedUrl(fromEndPart)
    const fromRelativeUrl = getRelativeUrl(from.path)

    switch (true) {
      case toMatchedPrettyUrl && toRelativeUrl === fromRelativeUrl:
        to.meta.key = toRelativeUrl
        break

      case toMatchedPrettyUrl && toRelativeUrl === from.path.replace('.html', ''):
        to.meta.key = toRelativeUrl
        break

      case fromMatchedPrettyUrl && fromRelativeUrl === to.path.replace('.html', ''):
        to.meta.key = fromRelativeUrl
        break

      default:
        to.meta.key = to.path.replace('.html', '')
    }
  }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    public: { storeUrl },
  } = useRuntimeConfig()
  const localePath = useLocalePath()
  const logger = useAppLogger('generalMiddleware.global.ts')

  const customerToken = useCookie(customerCookieName)
  const cartCookie = useCookie(cartCookieName)
  const loginRedirect = useCookie(loginRedirectCookieName)

  const cartId = cartCookie.value?.indexOf('|') ? cartCookie.value?.split('|').pop() : cartCookie.value

  const syncVueWithMagento = async (): Promise<void> => {
    if (!cartId || customerToken.value) return logger.info('[Magento Sync] Skipped')
    const url = `${storeUrl}${localePath('/')}/vue/cart/sync/token/${
      customerToken.value !== undefined ? customerToken.value : ''
    }/cart/${cartId}`

    logger.debug('[Magento Sync] URL: ', url)

    try {
      const response = await $fetch(url)
      if (!response.ok) {
        throw new Error(`status: ${response.status}`)
      }
      logger.debug('[Magento Sync] Done')
    } catch (error) {
      logger.error('[Magento Sync] Error:', error)
    }
  }

  // Add login redirect when going to the login url
  loginRedirect.value = to.path.includes('/customer/account/login') ? window?.location.href : undefined
  // Temporary workaround for Magento pages (when not dev), to make sure we leave the Nuxt app when navigating to these pages
  const magentoPages = ['/customer/account', '/wishlist', '/checkout']
  const isMagentoPage = magentoPages.some((page) => to.path.includes(page))
  const hasNoToken = !to.query.token

  if (isMagentoPage && hasNoToken) {
    const config = useRuntimeConfig()
    const targetUrl = config.public.storeUrl + to.path

    logger.info(`Redirecting to non-Nuxt page: ${targetUrl}`)

    if (!import.meta.dev) {
      await syncVueWithMagento()
      return navigateTo(targetUrl, { external: true })
    }
  }

  // Ignore unknown API routes for routing
  // IF route starts with /{locale}/api
  const regexPattern = /^\/([a-z]|-){2,5}\/api\//
  const knownApiRoutes = ['/api/exponea', '/api/bloomreach/page', '/api/bloomreach/pages', '/api/bloomreach/document']
  if (regexPattern.test(to.path) && !knownApiRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored API route: ${to.path}`)
    return abortNavigation()
  }

  const ignoredRoutes = ['/vue/cart/sync', '/sw.js', '_nuxt/node_modules']
  if (ignoredRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored route: ${to.path}`)
    return abortNavigation()
  }

  // Ignore routes with file extensions (except .html)
  if (!to.path.endsWith('.html') && /^.*\.[0-9a-z]+(\??.*).$/i.test(to.path)) {
    logger.info(`Ignored route: ${to.path}`)
    return abortNavigation()
  }

  generateRenderKey(to, from)
})
