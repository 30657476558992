import mask from '~/utils/mask'
import { useCart, useUiNotification } from '~/composables'
import { generateUserData } from './helpers/generateUserData'
import type { Customer } from '@vue-storefront/magento-types'
import type {
  UseUserInterface,
  UseUserLoadParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
} from './useUser'
import { cartCookieName, customerCookieName } from '~/enums/cookieNameEnum'

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export const useUser = (): UseUserInterface => {
  const logger = useAppLogger('useUser')
  const { $sdk, $i18n } = useNuxtApp()
  const { t, locale } = $i18n
  const customerStore = useCustomerStore()
  const router = useRouter()
  const { setCart } = useCart()
  const { setWishlist } = useWishlistStore()
  const { send: sendNotification } = useUiNotification()
  const loading: Ref<boolean> = ref(false)

  const errorState = reactive({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  })

  const cartCookie = useCookie(cartCookieName)
  const customerCookie = useCookie(customerCookieName)

  const setUser = (newUser: Customer) => {
    customerStore.setUser(newUser)
    logger.debug('useUserFactory.setUser', newUser)
  }

  const resetErrorValue = () => {
    errorState.updateUser = null
    errorState.register = null
    errorState.login = null
    errorState.logout = null
    errorState.changePassword = null
    errorState.load = null
  }

  const updateCustomerEmail = async (credentials: { email: string; password: string }): Promise<void> => {
    const { errors } = await $sdk.magento.updateCustomerEmail(credentials)

    if (errors) {
      throw errors.map((e) => e.message).join(',')
    }
  }

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery }: UseUserUpdateUserParams) => {
    logger.debug('[Magento] Update user information', { providedUser, customQuery })
    resetErrorValue()

    try {
      loading.value = true
      const { email: oldEmail } = customerStore.user
      const { email, password, ...updateData } = providedUser

      const userData = generateUserData(updateData)

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        })
      }

      const { data, errors } = await $sdk.magento.updateCustomer(userData, customQuery)
      logger.debug('[Result]:', { data })

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',')
        logger.error(allErrorMessages)
        errorState.updateUser = allErrorMessages
      }

      customerStore.user = data?.updateCustomerV2?.customer || {}
      errorState.updateUser = null
    } catch (err) {
      errorState.updateUser = err
      logger.error('useUser/updateUser', err)
    } finally {
      loading.value = false
    }
  }

  const logout = async ({ customQuery = {} }: UseUserLogoutParams = {}) => {
    logger.debug('[Magento] useUserFactory.logout')
    resetErrorValue()

    try {
      await $sdk.magento.revokeCustomerToken()

      setCart(null)
      setWishlist(null)
      customerStore.setIsLoggedIn(false)
      customerStore.setUser(null)
      cartCookie.value = null
      customerCookie.value = null
      errorState.logout = null
      window.exponea?.anonymize()
    } catch (err) {
      errorState.logout = err
      logger.error('useUser/logout', err)
    }
  }

  const load = async ({ customQuery = {} }: UseUserLoadParams = {}) => {
    logger.debug('[Magento] useUser.load')
    resetErrorValue()

    try {
      loading.value = true

      if (!customerCookie.value) {
        customerStore.setIsLoggedIn(false)
        customerStore.setUser(null)
        return null
      }
      try {
        const { data } = await $sdk.magento.customer(customQuery)

        logger.debug('[Result]:', { data })

        customerStore.setIsLoggedIn(true)
        customerStore.setUser(data?.customer ?? {})
        //Identify the loged in user and update segmentation cookie
        window.exponea?.identify(data?.customer?.email)
      } catch {
        await logout()
      }
      errorState.load = null
    } catch (err) {
      errorState.load = err
      logger.error('useUser/load', err)
    } finally {
      loading.value = false
    }

    return customerStore.user
  }

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async (email: string, password: string): Promise<void> => {
    logger.debug('[Magento] useUser.login')
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await $sdk.magento.generateCustomerToken({
        email: email,
        password: password,
      })
      logger.debug('[Result]:', { data })

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',')
        logger.error(joinedErrors)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors)
      }

      customerStore.setIsLoggedIn(true)

      customerCookie.value = data?.generateCustomerToken?.token

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const cartId = cartCookie.value
      const currentCartId = cartId?.indexOf('|') ? cartId.split('|').pop() : cartId
      const cart = await $sdk.magento.customerCart()
      const newCartId = cart.data.customerCart.id

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await $sdk.magento.mergeCarts(
            {
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            },
            { mergeCarts: 'merge-carts' },
          )

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts)
          cartCookie.value = `${dataMergeCart.mergeCarts.total_quantity}|${dataMergeCart.mergeCarts.id}`
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart)
          cartCookie.value = `${cart.data.customerCart.total_quantity}|${cart.data.customerCart.id}`
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart)
      }
      errorState.login = null
      load()
      router.push(`/${locale.value}/customer/account`)
    } catch (err) {
      errorState.login = err
      logger.error('useUser/login', err)
    } finally {
      loading.value = false
    }
  }

  // eslint-disable-next-line consistent-return
  const register = async (providedUser: UseUserRegisterParams): Promise<void> => {
    logger.debug('[Magento] useUser.register', providedUser)
    resetErrorValue()

    try {
      loading.value = true

      const { email, password, recaptchaToken, ...baseData } = generateUserData(providedUser)

      const { data, errors } = await $sdk.magento.createCustomer({
        email,
        password,
        recaptchaToken,
        ...baseData,
      })

      logger.debug('[Result]:', { data })

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',')
        logger.error(joinedErrors)
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: registerError.message,
            persist: true,
            title: 'Registration error',
            type: 'danger',
          }),
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors)
      }
      errorState.register = null
      let loginRecaptchaToken = ''
      // if ($recaptcha && recaptchaToken) {
      //   loginRecaptchaToken = await $recaptcha.getResponse()
      // }

      const customer = data?.createCustomerV2?.customer

      if (customer?.id) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: t('You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          })

          resolve()
        })
      }
      await login(email, password)
    } catch (err) {
      errorState.register = err
      logger.error('useUser/register', err)
    } finally {
      loading.value = false
    }
  }

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    logger.debug('[Magento] useUser.changePassword', {
      currentPassword: mask(params.current),
      newPassword: mask(params.new),
    })
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await $sdk.magento.changeCustomerPassword(
        {
          currentUser: customerStore.user,
          currentPassword: params.current,
          newPassword: params.new,
        },
        params.customQuery,
      )

      let joinedErrors = null

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',')
        logger.error(joinedErrors)
      }

      logger.debug('[Result] ', { data })

      customerStore.setUser(data?.changeCustomerPassword)
      errorState.changePassword = joinedErrors
    } catch (err) {
      errorState.changePassword = err
      logger.error('useUser/changePassword', err)
    } finally {
      loading.value = false
    }
  }

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    loading: readonly(loading),
    error: errorState,
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  }
}

export default useUser
export * from './useUser'
